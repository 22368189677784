// src/stores/useOnboardingStore.js
import { ref } from 'vue'
import { defineStore } from 'pinia'
import { fetchPaddleProducts } from '@/helpers/paddle'

export const useOnboardingStore = defineStore('onboardingStore', () => {
  // State
  const onboarding = ref(null)
  const products = ref(null)
  const loading = ref(false)
  const error = ref(null)
  const currentOnboardingId = ref(null)

  // Actions
  const loadOnboarding = async onboardingId => {
    if (currentOnboardingId.value === onboardingId && onboarding.value) {
      return
    }

    loading.value = true
    error.value = null

    try {
      // 1) Attempt to import the requested config file
      const module = await import(
        `@/constants/onboardings/${onboardingId}/index.js`
      )
      onboarding.value = module.default
      if (onboarding.value.products) {
        try {
          products.value = await fetchPaddleProducts(onboarding.value.products)
        } catch (e) {
          console.warn(
            `Failed to load products for onboarding '${onboardingId}'.`,
            e,
          )
        }
      } else {
        console.warn(`No products found for onboarding '${onboardingId}'.`)
        error.value = `No products found for onboarding '${onboardingId}'`
      }
      currentOnboardingId.value = onboardingId
    } catch (e) {
      console.warn(`Failed to load onboarding config for '${onboardingId}'.`, e)
      // 2) Optionally fallback to default config (onboarding-1) if the target fails
      try {
        const fallbackModule = await import(
          '@/constants/onboardings/onboarding-1/index.js'
        )
        onboarding.value = fallbackModule.default
        error.value = `Falling back to 'onboarding-1'`
        if (onboarding.value.products) {
          try {
            products.value = await fetchPaddleProducts(
              onboarding.value.products,
            )
          } catch (e) {
            console.warn(
              `Failed to load products for fallback onboarding 'onboarding-1'.`,
              e,
            )
            error.value = `Failed to load products for fallback onboarding 'onboarding-1'`
          }
        } else {
          console.warn(
            `No products found for fallback onboarding 'onboarding-1'.`,
          )
          error.value = `No products found for fallback onboarding 'onboarding-1'`
        }
      } catch (fallbackError) {
        error.value = 'Failed to load any onboarding configuration'
      }
    } finally {
      loading.value = false
    }
  }

  return {
    onboarding,
    products,
    loading,
    error,
    loadOnboarding,
  }
})
