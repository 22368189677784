import router from '../router'
import { useUserStore } from '../store/user'
import { getCerebroId, sendEvent } from './cerebro'
import { isStaging } from './environment'
import { requestWithIdToken } from './requestWithIdToken'
import { sleep } from '@/helpers/general'
import verifyPayment from '@/helpers/paymentVerification'
import { sendGTagEvent } from './gtag'
import { useOnboardingStore } from '@/store/onboarding'

if (isStaging) {
  Paddle.Environment.set('sandbox')
}
const initializeOptions = {
  token: import.meta.env.VITE_PADDLE_CLIENT_TOKEN,
  checkout: {
    settings: {
      displayMode: 'inline',
      theme: 'light',
      locale: 'en',
      frameTarget: 'checkout-container',
      frameInitialHeight: '450',
      frameStyle:
        'width: 100%; min-width: 200px; background-color: transparent; border: none;',
    },
  },
  eventCallback: async function (event) {
    if (isStaging) {
      console.log('paddle eventCallback:', event)
    }
    const userStore = useUserStore()
    const onboardingStore = useOnboardingStore()
    const QUIZ_ID = onboardingStore.onboarding?.id
    if (
      event.name == 'checkout.loaded' ||
      event.name == 'checkout.items.updated'
    ) {
      // capture totals
      var todayTotal = event.data.totals.total
      var recurringTotal = event.data.recurring_totals.total
      var oneTimeTotal = todayTotal - recurringTotal
      var currencyCode = event.data.currency_code

      if (isStaging) {
        console.log("Today's total: " + recurringTotal + ' ' + currencyCode)
      }
      sendGTagEvent({ event: 'purchase_started' })
      sendEvent({
        eventName: 'checkout_firstpage_displayed',
        properties: { quiz_id: QUIZ_ID },
      })
    } else if (event.name == 'checkout.payment.initiated') {
      const price =
        event.data.totals.total === 0
          ? event.data.recurring_totals.total
          : event.data.totals.total
      sendEvent({ eventName: 'checkout_purchase_clicked', quiz_id: QUIZ_ID })
    } else if (
      event.name == 'checkout.payment.failed' ||
      event.name == 'checkout.error'
    ) {
      router.push({
        name: 'PaymentResult',
        query: {
          success: false,
          cs: 'paddle',
          error: event?.error?.type ?? 'paddle_error',
          errorCode: event?.error?.code ?? 'paddle',
          errorMessage: event?.error?.detail ?? 'Paddle payment failed',
          paymentMethod: event.data.payment?.method_details?.type ?? '',
          src:
            (event.data?.totals?.discount || 0) > 0
              ? 'countdown_discount_1'
              : 'default',
        },
      })
    } else if (event.name == 'checkout.completed') {
      console.error('checkout completed')
      if (isStaging) {
        console.log('Checkout completed', event.data)
      }
    } else if (
      event.name == 'checkout.customer.created' ||
      event.name == 'checkout.customer.updated'
    ) {
      if (event.data?.status == 'ready') {
        sendEvent({
          eventName: 'checkout_secondpage_displayed',
          quiz_id: QUIZ_ID,
        })
      }
      const userEmail = userStore.user?.email
      const paddleCustomerEmail = event.data.customer.email
      if (userEmail != paddleCustomerEmail) {
        userStore.setEmailMismatch(true)
      }
      const updateData = {
        customerId: event.data.customer.id,
        paddleCustomerEmail: event.data.customer.email,
        userId: userStore.user?.uid,
        cerebroId: getCerebroId(),
      }
      const response = await requestWithIdToken({
        path: '/paddle/customer-update',
        method: 'POST',
        data: updateData,
      })
      if (response.data.success != true) {
        sendEvent({
          eventName: 'paddle_customer_update_failed',
          event_group: 'error',
          properties: {
            customer_id: updateData.customerId,
            paddle_customer_email: updateData.paddleCustomerEmail,
            cerebro_id: updateData.cerebroId,
            quiz_id: QUIZ_ID,
          },
        })
      } else {
        userStore.editUserData({
          paddleCustomerId: updateData.customerId,
          paddleCustomerEmail: updateData.paddleCustomerEmail,
        })
        sendEvent({
          eventName: 'paddle_customer_update',
          event_group: 'error',
          properties: {
            customer_id: updateData.customerId,
            paddle_customer_email: updateData.paddleCustomerEmail,
            cerebro_id: updateData.cerebroId,
            quiz_id: QUIZ_ID,
          },
        })
        initializePaddle()
      }
    }
  },
}

export const initializePaddle = pci => {
  const userStore = useUserStore()

  const paddleCustomerId = pci || userStore.userData?.paddleCustomerId
  if (Paddle.Initialized) {
    if (paddleCustomerId) {
      Paddle.Update({
        pwCustomer: {
          id: paddleCustomerId,
        },
      })
      if (isStaging) {
        console.log('updated paddle with customer id: ', paddleCustomerId)
      }
    }
  } else {
    if (paddleCustomerId) {
      Paddle.Initialize({
        ...initializeOptions,
        pwCustomer: {
          id: paddleCustomerId,
        },
      })
      if (isStaging) {
        console.log('initialized paddle with customer id: ', paddleCustomerId)
      }
    } else {
      Paddle.Initialize(initializeOptions)
      if (isStaging) {
        console.log('initialized paddle with default options')
      }
    }
  }
}

export const validatePurchase = async (trxId, count = 0) => {
  const userStore = useUserStore()
  const verificationResponse = await verifyPayment({ transactionId: trxId })
  if (verificationResponse) {
    userStore.editUserData({ subscriptions: verificationResponse })
    return 'success'
  } else {
    if (count < 20) {
      await sleep(500)
      return await validatePurchase(trxId, count + 1)
    } else {
      userStore.editUserData({ subscriptions: null })
      return null
    }
  }
}

export const fetchPaddleProducts = async productIds => {
  try {
    const products = await Paddle.PricePreview({ items: productIds })
    if (
      !products.data ||
      !products.data.details ||
      !products.data.details.lineItems ||
      products.data.details.lineItems.length === 0
    ) {
      sendEvent({
        eventName: 'paywall_products_failed_to_load',
      })
      return []
    }
    return formatPricesLikeStripe(products.data.details.lineItems)
  } catch (error) {
    sendEvent({
      eventName: 'paywall_products_failed_to_load',
    })
    console.log(error)
    return []
  }
}

const formatPricesLikeStripe = prices => {
  return prices.map(priceItem => {
    return {
      id: priceItem.price.id,
      object: 'price',
      currency: priceItem.price.unitPrice.currencyCode,
      nickname: priceItem.price.name,
      product: priceItem.product.id,
      offersFreeTrial:
        priceItem.price.trialPeriod?.frequency != null &&
        priceItem.price.trialPeriod?.interval != null,
      ...(priceItem.price.billingCycle && {
        recurring: {
          interval: priceItem.price?.billingCycle?.interval,
          interval_count: priceItem.price?.billingCycle?.frequency,
          trial_period_frequency: priceItem.price?.trialPeriod?.frequency,
          trial_period_interval: priceItem.price?.trialPeriod?.interval,
          usage_type: 'licensed',
        },
      }),
      type: priceItem.price.billingCycle ? 'recurring' : 'one-time',
      unit_amount: Number(priceItem.unitTotals.total),
      unit_amount_decimal: priceItem.unitTotals.total,
    }
  })
}

const calculateRevenue = price => {
  const fee = price * 0.05 + 0.5
  return price - fee
}
